import request from '@/utils/request'
// 获取简历详情
export function JS_GetMyResume(params) {
    return request({
        url: 'Resume/JS_GetMyResume',
        method: 'get',
        params
    })
}
// 保存简历信息
export function JS_SaveResume(data) {
    return request({
        url: 'Resume/JS_SaveResume',
        method: 'post',
        data
    })
}
// 获取指定简历的求职意向列表
export function JS_GetReJobwantList(params) {
    return request({
        url: 'Resume/JS_GetReJobwantList',
        method: 'get',
        params
    })
}
// 获取求职意向详情
export function JS_GetReJobwant(params) {
    return request({
        url: 'Resume/JS_GetReJobwant',
        method: 'get',
        params
    })
}
// 保存求职意向信息
export function JS_SaveReJobwant(data) {
    return request({
        url: 'Resume/JS_SaveReJobwant',
        method: 'post',
        data
    })
}
// 删除求职意向信息
export function JS_DeleteReJobwant(data) {
    return request({
        url: 'Resume/JS_DeleteReJobwant',
        method: 'post',
        data
    })
}
// 获取指定简历的工作经历列表
export function JS_GetReWorkList(params) {
    return request({
        url: 'Resume/JS_GetReWorkList',
        method: 'get',
        params
    })
}
// 获取工作经历信息
export function JS_GetReWork(params) {
    return request({
        url: 'Resume/JS_GetReWork',
        method: 'get',
        params
    })
}
// 保存工作经历
export function JS_SaveReWork(data) {
    return request({
        url: 'Resume/JS_SaveReWork',
        method: 'post',
        data
    })
}
// 删除工作经历
export function JS_DeleteReWork(data) {
    return request({
        url: 'Resume/JS_DeleteReWork',
        method: 'post',
        data
    })
}
// 获取指定简历的教育经历列表
export function JS_GetReEducationList(params) {
    return request({
        url: 'Resume/JS_GetReEducationList',
        method: 'get',
        params
    })
}
// 获取教育经历信息
export function JS_GetReEducation(params) {
    return request({
        url: 'Resume/JS_GetReEducation',
        method: 'get',
        params
    })
}
// 保存教育经历信息
export function JS_SaveReEducation(data) {
    return request({
        url: 'Resume/JS_SaveReEducation',
        method: 'post',
        data
    })
}
// 删除教育经历
export function JS_DeleteReEducation(data) {
    return request({
        url: 'Resume/JS_DeleteReEducation',
        method: 'post',
        data
    })
}
// 获取指定简历的项目经历列表
export function JS_GetReProjectList(params) {
    return request({
        url: 'Resume/JS_GetReProjectList',
        method: 'get',
        params
    })
}
// 获取项目经历信息
export function JS_GetReProject(params) {
    return request({
        url: 'Resume/JS_GetReProject',
        method: 'get',
        params
    })
}
// 保存项目经验信息
export function JS_SaveReProject(data) {
    return request({
        url: 'Resume/JS_SaveReProject',
        method: 'post',
        data
    })
}
// 删除项目经历
export function JS_DeleteReProject(data) {
    return request({
        url: 'Resume/JS_DeleteReProject',
        method: 'post',
        data
    })
}
// 获取指定简历的语言能力列表
export function JS_GetReLanguageList(params) {
    return request({
        url: 'Resume/JS_GetReLanguageList',
        method: 'get',
        params
    })
}
// 获取语言能力信息
export function JS_GetReLanguage(params) {
    return request({
        url: 'Resume/JS_GetReLanguage',
        method: 'get',
        params
    })
}
// 保存语言能力信息
export function JS_SaveReLanguage(data) {
    return request({
        url: 'Resume/JS_SaveReLanguage',
        method: 'post',
        data
    })
}
// 删除语言能力
export function JS_DeleteReLanguage(data) {
    return request({
        url: 'Resume/JS_DeleteReLanguage',
        method: 'post',
        data
    })
}
// 获取指定简历的专业技能列表
export function JS_GetReSkillList(params) {
    return request({
        url: 'Resume/JS_GetReSkillList',
        method: 'get',
        params
    })
}
// 获取专业技能详情
export function JS_GetReSkill(params) {
    return request({
        url: 'Resume/JS_GetReSkill',
        method: 'get',
        params
    })
}
// 保存专业技能
export function JS_SaveReSkill(data) {
    return request({
        url: 'Resume/JS_SaveReSkill',
        method: 'post',
        data
    })
}
// 删除专业技能
export function JS_DeleteReSkill(data) {
    return request({
        url: 'Resume/JS_DeleteReSkill',
        method: 'post',
        data
    })
}
// 获取指定简历的证书列表
export function JS_GetReCertificateList(params) {
    return request({
        url: 'Resume/JS_GetReCertificateList',
        method: 'get',
        params
    })
}
// 获取证书信息
export function JS_GetReCertificate(params) {
    return request({
        url: 'Resume/JS_GetReCertificate',
        method: 'get',
        params
    })
}
// 保存证书信息
export function JS_SaveReCertificate(data) {
    return request({
        url: 'Resume/JS_SaveReCertificate',
        method: 'post',
        data
    })
}
// 删除证书
export function JS_DeleteReCertificate(data) {
    return request({
        url: 'Resume/JS_DeleteReCertificate',
        method: 'post',
        data
    })
}
// 获取指定类别的代码列表
export function GetCodeEntrysList(params) {
    return request({
        url: 'Code/GetCodeEntrysList',
        method: 'get',
        params
    })
}
// 获取指定简历的培训经历列表
export function JS_GetReTrainList(params) {
    return request({
        url: 'Resume/JS_GetReTrainList',
        method: 'get',
        params
    })
}
// 保存培训经历信息
export function JS_SaveReTrain(data) {
    return request({
        url: 'Resume/JS_SaveReTrain',
        method: 'post',
        data
    })
}
// 获取职位信息列表
export function JS_GetJobPage(params) {
    return request({
        url: 'Job/JS_GetJobPage',
        method: 'get',
        params
    })
}
// 获取职位信息列表
export function WeGetJobPage(params) {
    return request({
        url: 'Job/WeGetJobPage',
        method: 'get',
        params
    })
}
// 投递简历
export function JS_ReDeliver(data) {
    return request({
        url: 'Redeliver/JS_ReDeliver',
        method: 'post',
        data
    })
}
// 获取职业类型信息列表
export function GetAbilityList(params) {
    return request({
        url: 'Ability/GetAbilityList',
        method: 'get',
        params
    })
}
// 获取个人信息
export function GetLoginSeeker(data) {
    return request({
        url: 'JSPlat/GetLoginSeeker',
        method: 'post',
        data
    })
}
// 获取我投递的简历
export function JS_GetMyReDeliverList(params) {
    return request({
        url: 'Redeliver/JS_GetMyReDeliverList',
        method: 'get',
        params
    })
}
// 修改密码
export function paasswords(data) {
    return request({
        url: 'JSPlat/ChangePwd',
        method: 'post',
        data
    })
}
// 验证者用户
export function CheckSeeker(data) {
    return request({
        url: 'JSPlat/CheckSeeker',
        method: 'post',
        data
    })
}
//注册用户，第一步，基本信息注册及验证
export function RegisterOne(data) {
    return request({
        url: 'JSPlat/RegisterOne',
        method: 'post',
        data
    })
}
//注册完成
export function RegisterOver(data) {
    return request({
        url: 'JSPlat/RegisterOver',
        method: 'post',
        data
    })
}
// 获取短信验证码
export function SendValidedMsg(data) {
    return request({
        url: 'Plat/SendValidedMsg',
        method: 'post',
        data
    })
}
// 生成图片验证码
export function GenerateImgValidCode(params) {
    return request({
        url: 'Plat/GenerateImgValidCode',
        method: 'get',
        params
    })
}
// 删除项目经历
export function JS_DeleteReTrain(data) {
    return request({
        url: 'Resume/JS_DeleteReTrain',
        method: 'post',
        data
    })
}
// 获取企业详情
export function WxGetEnterprise(params) {
    return request({
        url: 'Enterprise/WxGetEnterprise',
        method: 'get',
        params
    })
}
// 获取职位详情
export function WeGetJobDetail(params) {
    return request({
        url: 'Job/WeGetJobDetail',
        method: 'get',
        params
    })
}