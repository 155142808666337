export default {
  area: '西霞院街道数智管理平台',
  objectName: '',
  //url
  hostUrl: 'http://jlhyjd.anluoyun.cn',
  // 河阳街道 https://mjxxyjdapi.anluoyun.cn/
  apiHttpsUrl: 'https://mjxxyjdapi.anluoyun.cn/',
  apiRequestUrl: 'https://mjxxyjdapi.anluoyun.cn/api',
  apiUploadUrl: 'https://mjxxyjdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles:
    'https://mjxxyjdapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadvideo:
    'https://mjxxyjdapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}
